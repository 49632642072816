<template>
  <div class="mt-3">
    <v-row class="mt-5">
      <v-col class="d-flex flex-column content-responsive" cols="12" md="4">
        <p class="ma-0 primary-text--text">
          <strong>{{ productiveUnity.nome }}</strong>
        </p>
        <btn-link
          v-if="
            productiveUnity.pode_cancelar && canSeeCancelProductiveUnitInvite
          "
          @click="$emit('removeProductiveUnity', productiveUnity)"
          >{{ $t("labels.cancel_invite") }}</btn-link
        >
      </v-col>
      <v-col cols="12" md="4">
        <detail-status
          class="content-responsive"
          label="ABR"
          :urlTerm="urlTerm"
          :status="productiveUnity.status_abr"
        />
      </v-col>
      <v-col cols="12" md="4">
        <detail-status
          class="content-responsive"
          label="BCI"
          :status="productiveUnity.status_bci"
        />
      </v-col>
    </v-row>
    <v-row class="mt-5">
      <v-col class="content-responsive" cols="12" md="5">
        <h3 class="secondary-text--text">
          {{ $t("text.titulo_unidade_produtiva_responsaveis_convidados") }}
        </h3>
      </v-col>
    </v-row>
    <div class="mb-4 content-responsive">
      <v-btn
        elevation="1"
        color="primary"
        class="px-4"
        @click="addResponsible()"
      >
        {{ $t("text.adicionar_responsavel") }}
      </v-btn>
    </div>
    <table-responsibles
      :responsibles="productiveUnity.responsaveis"
      @editResponsible="handleEditResponsible"
    />
    <dialog-invite-user
      :dialogActivate="dialogInviteUsers"
      @onCloseDialog="onCancelDialogInviteUser"
      @inviteUser="confirmInsertUser"
    />
    <v-row class="mt-5">
      <v-col class="content-responsive" cols="12" md="9">
        <p class="ma-0 my-4 secondar-text--text">
          {{ $t("text.informativo_acesso_unidade_produtiva_safra_vigente") }}
        </p>
      </v-col>
      <v-col class="content-responsive" cols="12" md="3" align-self="center">
        <v-btn
          v-if="canSeeResendInviteAbrBci"
          outlined
          block
          color="primary"
          @click="$emit('resendInviteProductiveUnity', productiveUnity)"
          >Reenviar Convite</v-btn
        >
      </v-col>
    </v-row>
    <v-divider></v-divider>
  </div>
</template>
<script>
import DetailStatus from "@/modules/inviteds/views/components/BtnDetailStatus";
import BtnLink from "@/modules/core/views/components/BtnLink";
import TableResponsibles from "@/modules/inviteds/views/components/TableResponsibles";
import DialogInviteUser from "@/modules/invite/views/components/DialogInviteUser";
import { mapActions } from "vuex";

export default {
  props: {
    productiveUnity: {
      type: Object,
      required: true
    },
    urlTerm: {
      type: String,
      required: false
    },
    state: {
      type: Object,
      required: true
    },
    canSeeResendInviteAbrBci: {
      type: Boolean,
      required: true
    },
    canSeeRemoveAbrResponsible: {
      type: Boolean,
      required: true
    },
    canSeeCancelProductiveUnitInvite: {
      type: Boolean,
      required: true
    }
  },
  components: {
    BtnLink,
    DetailStatus,
    TableResponsibles,
    DialogInviteUser
  },
  data: () => ({
    dialogInviteUsers: {
      value: false
    }
  }),
  methods: {
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("invite", ["saveInvite"]),

    //action add responsable
    addResponsible() {
      this.dialogInviteUsers.isEdit = false;
      this.dialogInviteUsers.value = true;
      this.dialogInviteUsers.initialData = {};
    },
    async confirmInsertUser(payload) {
      const { data, isEdit } = payload;
      this.dialogInviteUsers.value = false;

      if (this.productiveUnity.responsaveis.find(x => x.cpf == data.cpf)) {
        this.toggleSnackbar({
          text: "Responsável ja cadastrado!",
          type: "error"
        });
        return;
      }
      if (data.id == "") {
        data.id = null;
      }
      const addResponsableUP = {
        produtor_id: this.state.produtor_id,
        tipo_produtor: this.state.tipo_produtor.id,
        abr: {
          convidados: [data],
          unidades_produtivas: [this.productiveUnity.id]
        }
      };

      const saved = await this.saveInvite(addResponsableUP);
      if (saved) {
        this.toggleSnackbar({
          text: "Responsável salvo com sucesso!",
          type: "success"
        });
        this.productiveUnity.responsaveis.push(data);
      } else {
        this.toggleSnackbar({
          text: "Responsável não cadastrado!",
          type: "error"
        });
      }
    },
    onCancelDialogInviteUser() {
      this.dialogInviteUsers.value = false;
      this.dialogInviteUsers.isEdit = false;
    },

    handleEditResponsible(responsible) {
      this.$emit("updateResponsibleAbrbci", responsible);
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .content-responsive {
    align-items: center;
    text-align: center;
  }
}
</style>
