<template>
  <div class="d-flex flex-column content-column">
    <p class="ma-0 label secondary-text--text d-flex">
      <span class="label mr-1">{{ label }}: </span>
      <status :value="status.id">{{ status.descricao }}</status>
    </p>
  </div>
</template>
<script>
import Status from "@/modules/inviteds/views/components/Status";

export default {
  components: {
    Status
  },
  props: {
    status: {
      type: Object,
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
};
</script>
<style lang="scss" scoped>
.label {
  text-transform: uppercase;
}

@media screen and (max-width: 960px) {
  .content-column {
    align-items: center;
  }
}
</style>
