<template>
  <v-data-table
    :items="productiveUnits"
    :headers="headers"
    :hide-default-footer="true"
    :items-per-page="1000"
    :no-data-text="$t('text.nenhum_registro_selecionado_tabelas')"
  >
    <template v-slot:[`item.matriculas`]="{ item }">
      <registrations
        v-if="item.matriculas && !!item.matriculas.length"
        :values="item.matriculas"
        :maxLength="2"
      />
    </template>
    <template v-slot:[`item.acao`]="{ item }">
      <v-btn
        outlined
        color="primary"
        v-if="isInviteStatusSent(item)"
        class="button"
        @click="$emit('reviewTerm', item)"
        >Preencher/Revisar Termo de Adesão</v-btn
      >
      <div v-if="!isInviteStatusSent(item)" class="d-flex">
        <labelled-status :status="item.status_abr" label="ABR" />
        <labelled-status class="ml-3" :status="item.status_bci" label="BCI" />
      </div>
    </template>
  </v-data-table>
</template>
<script>
import Registrations from "@/modules/invite/views/components/Registrations";
import LabelledStatus from "@/modules/inviteds/views/components/LabelledStatus";

import { InviteStatus } from "@/modules/core/enums/invite-status.enum";

export default {
  props: {
    productiveUnits: {
      type: Array,
      required: true
    }
  },
  components: {
    Registrations,
    LabelledStatus
  },
  computed: {
    headers() {
      return [
        { text: "ID", value: "id" },
        { text: this.$t("labels.unity_productive"), value: "nome" },
        { text: this.$t("labels.city"), value: "municipio" },
        { text: this.$tc("labels.registration", 2), value: "matriculas" },
        { text: "Ação", value: "acao" }
      ];
    }
  },
  methods: {
    isInviteStatusSent(productiveUnit) {
      return productiveUnit.status_abr.id === InviteStatus.INVITE_SENT.id;
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .button {
    white-space: normal;
    height: auto !important;
    padding: 12px 8px !important;
    max-width: 190px;

    ::v-deep .v-btn__content {
      width: 100%;
    }
  }
}
</style>
