<template>
  <v-dialog v-model="state.value" width="980" scrollable persistent>
    <v-card>
      <v-card-title class="primary--text text-align-responsible">{{
        $t("labels.manage_invite")
      }}</v-card-title>
      <v-card-text>
        <producer-or-group-detail class="my-4" :state="state.managingInvite" />
        <v-divider></v-divider>
        <h3
          v-if="state.managingInvite.unidades_produtivas.length"
          class="mt-3 primary--text text-align-responsible"
        >
          {{ $t("text.titulo_unidades_produtivas_convidadas_abr_bci") }}
        </h3>

        <v-alert
          v-if="
            state.managingInvite.unidades_produtivas &&
              state.managingInvite.unidades_produtivas.length
          "
          class="mt-3"
          type="info"
        >
          {{ this.$t("text.informativo_safra") }}
        </v-alert>
        <div class="col-md-12">
          <div class="col-md-4">
            <v-combobox
              label="Termo de Adesão"
              :items="termo_files"
              @change="openTerm($event)"
              variant="outlined"
            ></v-combobox>
          </div>
        </div>
        <manage-invites-abr-section
          v-for="(productiveUnity, i) in state.managingInvite
            .unidades_produtivas"
          :productiveUnity="productiveUnity"
          :urlTerm="files.termo_abr_bci"
          :statusSBRHVI="state.managingInvite.status_sbrhvi"
          :key="`manage-invites-abr-section-${i}`"
          :state="state.managingInvite"
          :canSeeResendInviteAbrBci="
            profile.canSeeResendInviteAbrBci &&
              canSeeResendInviteAbrBciBySituationInviteSent(productiveUnity)
          "
          :canSeeRemoveAbrResponsible="profile.canSeeRemoveAbrResponsible"
          :canSeeCancelProductiveUnitInvite="
            profile.canSeeCancelProductiveUnitInvite
          "
          @resendInviteProductiveUnity="
            handleResendInviteProductiveUnit($event)
          "
          @removeProductiveUnity="handleRemoveProductiveUnity"
          @removeResponsibleProductiveUnity="
            handleRemoveResponsibleProductiveUnity
          "
          @updateResponsibleAbrbci="handleUpdateResponsibleAbrbci"
        />

        <div v-if="state.managingInvite.possui_insencao_sbrhvi">
          <v-alert class="mt-3" type="info">
            {{ $t("text.producer_with_exempt_sbrhvi") }} <br />
          </v-alert>
        </div>
        <div v-else>
          <h3 class="my-4 primary--text text-align-responsible">
            {{ $t("text.titulo_responsavel_lider_sbrhvi") }}
          </h3>

          <div v-if="state.managingInvite.unidades_produtivas.length > 0">
            <manage-invites-sbrhvi-section
              v-for="(productiveUnity, i) in state.managingInvite
                .unidades_produtivas"
              :productiveUnity="productiveUnity"
              :urlTerm="files.termo_sbrhvi"
              :statusSBRHVI="state.managingInvite.status_sbrhvi"
              :key="`manage-invites-sbrhvi-section-${i}`"
              :responsibleSBRHVI="state.managingInvite.responsaveis_sbrhvi[0]"
              @removeSbrHviUser="handleRemoveSbrHviUser"
              @update-manager-leader="handleUpdateManagerLeader"
              :canSeeUpdateSbrhviResponsible="
                profile.canSeeUpdateSbrhviResponsible && canResendInviteSbrhvi()
              "
              @resend-invite-responsible-sbrhvi="
                handleResendInviteResponsibleSbrhi
              "
            />
          </div>

          <div v-else>
            <manage-invites-sbrhvi-section
              :urlTerm="files.termo_sbrhvi"
              :statusSBRHVI="state.managingInvite.status_sbrhvi"
              :key="`manage-invites-sbrhvi-section-${0}`"
              :responsibleSBRHVI="state.managingInvite.responsaveis_sbrhvi[0]"
              @removeSbrHviUser="handleRemoveSbrHviUser"
              @update-manager-leader="handleUpdateManagerLeader"
              :canSeeUpdateSbrhviResponsible="
                profile.canSeeUpdateSbrhviResponsible && canResendInviteSbrhvi()
              "
              @resend-invite-responsible-sbrhvi="
                handleResendInviteResponsibleSbrhi
              "
            />
          </div>

          <h3 class="my-4 primary--text text-align-responsible">
            {{ $t("text.titulo_demais_responsaveis_sbrhvi") }}
          </h3>

          <div
            v-if="
              state.managingInvite &&
                state.managingInvite.responsaveis_sbrhvi_registrados.length
            "
          >
            <manage-invites-sbrhvi-section
              :key="`manage-invites-sbrhvi-section-${index}`"
              v-for="(responsavel, index) in state.managingInvite
                .responsaveis_sbrhvi_registrados"
              :responsibleSBRHVI="responsavel"
            />
          </div>

          <div v-else>
            <p class="primary-text--text text-align-responsible">
              {{ $t("text.titulo_nenhum_responsavel_cadastrado") }}
            </p>
          </div>

          <dialog-invite-user
            :dialogActivate="dialogInviteUserState"
            @onCloseDialog="handleOnCloseDialog"
            @inviteUser="handleInviteUser"
          />

          <note class="my-5">{{
            $t(
              "text.informativo_alterar_responsavel_sbrhvi_antes_aceite_convite"
            )
          }}</note>
        </div>
        <!-- end if possui_insencao_sbrhvi -->
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="d-flex justify-center align-center">
        <v-btn text color="primary" @click="state.value = false">{{
          $t("labels.close")
        }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions } from "vuex";

import Note from "@/modules/core/views/components/Note";
import ProducerOrGroupDetail from "@/modules/inviteds/views/components/ProducerOrGroupDetail";
import ManageInvitesAbrSection from "@/modules/inviteds/views/components/ManageInvitesAbrSection";
import ManageInvitesSbrhviSection from "@/modules/inviteds/views/components/ManageInvitesSbrhviSection";
import DialogInviteUser from "@/modules/invite/views/components/DialogInviteUser";
import { InviteStatus } from "@/modules/core/enums/invite-status.enum";
import { Profile } from "@/modules/inviteds/enums/profile.enum";

import { mapGetters } from "vuex";

export default {
  props: {
    state: {
      type: Object,
      required: true
    }
  },

  components: {
    ProducerOrGroupDetail,
    ManageInvitesAbrSection,
    ManageInvitesSbrhviSection,
    Note,
    DialogInviteUser
  },

  data: () => ({
    dialogInviteUserState: {
      value: false
    },
    termo_files: [],

    files: {
      termo_hvi: "",
      termo_abr_bci: "",
      termo_bci: ""
    },
    profiles: {
      [Profile.STATE.id]: {
        canSeeCancelProductiveUnitInvite: true,
        canSeeRemoveAbrResponsible: true,
        canSeeUpdateSbrhviResponsible: true,
        canSeeResendInviteAbrBci: true,
        canSeeResendInviteSbrHvi: true
      },
      [Profile.ABRAPA.id]: {
        canSeeCancelProductiveUnitInvite: false,
        canSeeRemoveAbrResponsible: false,
        canSeeUpdateSbrhviResponsible: false,
        canSeeResendInviteAbrBci: false,
        canSeeResendInviteSbrHvi: false
      },
      [Profile.INVITED.id]: {
        canSeeCancelProductiveUnitInvite: false,
        canSeeRemoveAbrResponsible: false,
        canSeeUpdateSbrhviResponsible: false,
        canSeeResendInviteAbrBci: false,
        canSeeResendInviteSbrHvi: false
      }
    },
    link_term_abr: null,
    link_term_sbrhvi: null
  }),

  computed: {
    ...mapGetters({
      userProfile: "inviteds/getUserProfile"
    }),

    profile() {
      return this.profiles[this.userProfile.id];
    }
  },
  mounted() {
    this.fetchTermsUrls();
  },
  methods: {
    ...mapActions("invite", [
      "fetchAbrTerm",
      "fetchSbrHviTerm",
      "fetchTermDownload"
    ]),
    ...mapActions("invite", ["getTermsUrls"]),
    ...mapActions(["toggleSnackbar"]),
    ...mapActions("invite", [
      "cancelInviteSBRHVI",
      "cancelInviteProductiveUnity",
      "cancelInviteResponsibleProductiveUnity",
      "resendInvite",
      "resendInviteSbrhvi",
      "updateManagerLeader"
    ]),
    ...mapActions("invited", ["updateResponsible"]),

    handleUpdateResponsibleAbrbci(responsible) {
      this.callFetchData(
        async () => this.updateResponsible({ ...responsible }),
        "text.responsavel_alterado_sucesso"
      );
    },

    async fetchTermsUrls() {
      const payload = {
        produtor_id: this.state.producer.produtor_id,
        tipo_produtor: this.state.producer.tipo_produtor.id,
        unidade_produtiva_id: this.state.producer.unidades_produtivas[0]?.id,
        abr_bci: 0,
        sbrhvi: true
      };

      try {
        const response = await this.getTermsUrls(payload);
        this.files = response.files;
        const respData = await this.fetchTermDownload(payload);

        this.files.termo_abr_bci = respData.abr;
        if (this.files.termo_abr_bci) {
          this.termo_files.push("ABR / BCI");
        }

        this.files.termo_hvi = respData.hvi;
        if (this.files.termo_hvi) {
          this.termo_files.push("SBRHVI");
        }

        console.log("files:: ", this.files);
      } catch (error) {
        console.log("Erro ao pegar arquivos");
      }
    },
    openTerm(event) {
      if (event == "ABR / BCI") {
        window.open(this.files.termo_abr_bci, "_blank");
      }
      if (event == "SBRHVI") {
        window.open(this.files.termo_hvi, "_blank");
      }
    },

    handleResendInviteResponsibleSbrhi() {
      this.callFetchData(
        async () =>
          this.resendInviteSbrhvi({ ...this.getProductiveUnityDataKeys() }),
        "text.mail_resend_success"
      );
    },

    handleOnCloseDialog() {
      this.dialogInviteUserState.value = false;
    },

    async handleInviteUser(data) {
      const keys = { ...this.getProductiveUnityDataKeys() };
      const payload = { ...data.data };

      this.callFetchData(async () => {
        await this.updateManagerLeader({ keys, payload });
        this.dialogInviteUserState.value = false;
      }, "text.responsavel_alterado_sucesso");
    },

    handleUpdateManagerLeader() {
      this.dialogInviteUserState.value = true;
    },

    getProductiveUnityDataKeys() {
      return {
        produtor_id: this.state.managingInvite.produtor_id,
        tipo_produtor: this.state.managingInvite.tipo_produtor.id
      };
    },

    handleRemoveResponsibleProductiveUnity(responsible) {
      this.$confirmDialog.open(this.$t("labels.confirm_action"), () =>
        this.removeResponsibleProductiveUnity(responsible)
      );
    },

    async removeResponsibleProductiveUnity(responsible) {
      const payload = {
        ...this.getProductiveUnityDataKeys(),
        unidade_produtiva_id: responsible.unidade_produtiva_id,
        convidado_id: responsible.id
      };

      this.callFetchData(async () =>
        this.cancelInviteResponsibleProductiveUnity(payload)
      );
    },

    handleRemoveSbrHviUser(productiveUnit) {
      this.$confirmDialog.open(this.$t("labels.confirm_action"), () =>
        this.removeSbrHviUser(productiveUnit)
      );
    },

    handleRemoveProductiveUnity(productiveUnity) {
      this.$confirmDialog.open(this.$t("labels.confirm_action"), () =>
        this.removeProductiveUnity(productiveUnity)
      );
    },

    canSeeResendInviteAbrBciBySituationInviteSent(productiveUnit) {
      return productiveUnit?.status_abr?.id === InviteStatus.INVITE_SENT.id;
    },

    canResendInviteSbrhvi() {
      return (
        this.state.managingInvite.status_sbrhvi !== null &&
        this.state.managingInvite.status_sbrhvi.id ===
          InviteStatus.INVITE_SENT.id
      );
    },

    async removeSbrHviUser(productiveUnit) {
      const payload = {
        ...this.getProductiveUnityDataKeys(),
        unidade_produtiva_id: productiveUnit.id
      };
      this.callFetchData(async () => this.cancelInviteSBRHVI(payload));
    },

    async removeProductiveUnity(productiveUnity) {
      const payload = {
        ...this.getProductiveUnityDataKeys(),
        unidade_produtiva_id: productiveUnity.id
      };

      this.callFetchData(async () => this.cancelInviteProductiveUnity(payload));
    },

    async handleResendInviteProductiveUnit(productiveUnit) {
      const payload = {
        ...this.getProductiveUnityDataKeys(),
        unidade_produtiva_id: productiveUnit.id
      };

      this.callFetchData(
        async () => this.resendInvite(payload),
        "text.mail_resend_success"
      );
    },

    async callFetchData(fn, successMessage) {
      try {
        await fn();

        this.toggleSnackbar({
          text: this.$t(successMessage ?? "text.success_cancel_invite"),
          type: "success"
        });

        this.updateData();
      } catch (error) {
        this.toggleSnackbar({
          text: error.data?.detail ?? this.$t("text.erro_interno"),
          type: "error"
        });
      }
    },

    updateData() {
      this.$emit("update", { ...this.state.producer });
    }
  }
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 960px) {
  .text-align-responsible {
    text-align: center;
    display: flex;
    justify-content: center;
  }
}
</style>
